.exhibmanV2.exhibman-profile {
	.profile-quick-menu {
		padding: 15px 0;
		&__jump {
			p {
				margin: 0;
			}
		}
		&__list {
			display: inline-flex;
			justify-content: flex-end;
			padding: 0 20px;
			.profile-quick-menu__type {
				border-right: 1px solid black;
				padding: 0 15px;
				height: fit-content;
				cursor: pointer;
				p {
					margin: 0;
					line-height: normal;
					height: fit-content;
				}
				&:hover {
					text-decoration: underline;
				}
				&:first-child {
					padding-left: 0;
				}
				&:last-child {
					border-right: none;
					padding-right: 0;
				}
			}
		}
	}
	.profile-summary {
		padding: 20px 0;
		border-top: 1px solid black;
		.profile-logo {
		    max-width: 400px;
			padding: 10px;
			@media (min-width: $mobile-width) {
				float: left;
			}
		
		}
		.profile-bios {
		}
	}
	.profile-assets {
		padding: 20px 0;
		border-top: 1px solid black;
		&.profile-products {
			clear: both;
			.profile-product{
				& + .profile-product{
					margin-top: 20px;
				}
			}
		}
		&.profile-news {
			clear: both;
			.profile-news{
				& + .profile-product{
					margin-top: 20px;
				}
			}
		}
		&.profile-videos {
			.profile-video {
				& > div {
					position: relative;
					padding-bottom: 75%;
					overflow: hidden;
					iframe {
						border: 0;
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
					}
				}
				p {
					text-align: center;
				}
			}
		}
		&.profile-downloadables {
			.profile-downloadable {
				& > div {
					display: flex;
					flex-direction: column;
					align-items: center;
					a {
						position: relative;
						img {
							max-height: 200px;
						}
					}
					p {
						margin: 10px 0;
						text-align: center;
					}
					.fi {
						position: absolute;
						bottom: -5px;
						right: -10px;
						.fi-content {
							padding-top: 10px;
							writing-mode: vertical-rl;
						}
					}
				}
			}
		}
	}
	&.exhibman-profile--Enhanced {
		.profile-products {
			.profile-product {
				.profile-product__text {
					h5 {
						cursor: pointer;
						&:hover {
							text-decoration: underline;
						}
					}
				}
				.profile-product__text {
					p span {
						cursor: pointer;
						&:hover {
							text-decoration: underline;
						}
					}
				}
			}
		}
	}
}
